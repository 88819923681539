<template>
  <div class="home-content">
    <el-container style="width: 100%;height: 100%">
      <el-header class="system-header">
        <h3 style="color: #ffffff;text-align: center">甘肃省社会体育管理中心 <br> 赛事评分系统</h3>
        <el-dropdown trigger="click" @command="handleCommand">
      <span class="el-dropdown-link" style="color: #ffffff">
       管理员, 你好<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="editPassword">修改密码</el-dropdown-item>
            <el-dropdown-item command="quitLogin">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-header>
      <el-container style="height: 100%">
        <el-aside width="200px" style="height: 100%">
          <el-menu style="border-right: 0"
                   :default-active="$route.path"
                   background-color="#f46e55"
                   unique-opened
                   text-color="#fff"
                   active-text-color="#b14040"
                   router
          >
            <el-submenu
                :index="item.id"
                v-for="item in meauList"
                :key="item.id"
            >
              <template slot="title">
                <i :class="item.icon" style="color: #fff"></i>
                <span>{{ item.name }}</span>
              </template>
              <el-menu-item
                  :index="'/' + item1.path"
                  v-for="item1 in item.child"
                  :key="item1.id"
              >{{ item1.childName }}
              </el-menu-item
              >
            </el-submenu>
          </el-menu>
        </el-aside>
        <el-main >
          <router-view/>
        </el-main>
      </el-container>
    </el-container>

    <el-dialog :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" title="修改密码"
               :visible.sync="showPassword" width="30%" center :before-close="beforeClose">
      <div style="display: flex;justify-content: center">
        <el-form label-width="auto" label-position="left" :model="passData" :rules="rules" ref="ruleForm">
          <el-form-item label="旧密码:" prop="oldPassword">
            <el-input type="password" show-password style="width: 300px" size="medium"
                      v-model="passData.oldPassword"
                      placeholder="请输入旧密码"></el-input>
          </el-form-item>
          <el-form-item label="新密码:" prop="newPassword">
            <el-input type="password" show-password style="width: 300px" size="medium"
                      v-model="passData.newPassword"
                      placeholder="请输入新密码"></el-input>
          </el-form-item>
          <el-form-item label="确定新密码:" prop="newCheckPassword">
            <el-input type="password" show-password style="width: 300px" size="medium"
                      v-model="passData.newCheckPassword"
                      placeholder="请确认新密码"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="beforeClose('ruleForm')">取 消</el-button>
        <el-button size="medium" style="background-color: #bf353b;color: #ffffff"
                   @click="updatePass('ruleForm')">
          确 定
        </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {quitLogin, updatePassword} from "../api/adminLogin";
import common from "@/utils/common";

export default {
  name: 'Home',
  data() {
    return {
      showPassword: false,
      meauList: [
        {
          name: '赛事管理',
          id: '1',
          icon: 'el-icon-odometer',
          child: [
            {
              childName: '赛事列表',
              id: '11',
              path: 'eventList'
            },
            // {
            //     childName: '创建赛事',
            //     id: '12',
            //     path: 'createEvent'
            // },
            // {
            //   childName: '赛事链接',
            //   id: '13',
            //   path: 'eventLink'
            // }
          ]
        },
      ],
      passData: {
        newCheckPassword: "",
        newPassword: "",
        oldPassword: "",
      },
      rules: {
        oldPassword: [
          {required: true, message: '请输入旧密码', trigger: 'blur'},
          {min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur'}
        ],
        newPassword: [
          {required: true, message: '请输入新密码', trigger: 'blur'},
          {min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur'}
        ],
        newCheckPassword: [
          {required: true, message: '请再次输入新密码', trigger: 'blur'},
          {min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur'}
        ],
      },
      userObj: {},
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    beforeClose(formName) {
      this.$refs[formName].resetFields();
      this.showPassword = false;
    },
    updatePass(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          updatePassword(this.passData).then(res => {
            if (res.data.code === 200) {
              sessionStorage.clear();
              this.showPassword = false
              this.$message.success('修改密码成功');
              this.$router.push('/')
            }
          })
        } else {
          return false;
        }
      });
    },
    handleCommand(command) {
      console.log(command)
      if (command === 'quitLogin') {
        this.logout()
      } else {
        this.showPassword = true
      }
    },
    logout() {
      this.$confirm('确定退出登录, 是否继续?', '注销', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        quitLogin().then(data => {
          if (data.data.code === 200) {
            sessionStorage.clear();
            this.$router.replace('/')
            this.$message({
              type: 'success',
              message: '注销成功!'
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消注销'
        });
      });
    },
  }
}
</script>
<style lang="less" scoped>
.home-content {
  width: 100%;
  height: 100%;

  .el-container {
    width: 100%;
    height: 100%;

    .system-header {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #bf353b;
    }

    /deep/ .el-aside {
      height: 100%;
      background-color: #f46e55;

      .aside-header {
        width: 100%;
        height: 100px;
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    
  }
}
</style>
