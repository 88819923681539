// 导入 request
import request from '../utils/request'
import da from "element-ui/src/locale/lang/da";

// 管理员登录
export const userLogin = data => {
    return request({
        method: 'POST',
        url: '/admin/login',
        data
    })
}

//评委登陆
export const judgeLogin = data => {
    return request({
        method: 'POST',
        url: '/admin/judgeLogin',
        data
    })
}

//主持登陆
export const hostLogin = linkCode => {
    return request({
        method: 'POST',
        url: '/admin/hostLogin?linkCode=' + linkCode
    })
}

//大屏登陆
export const showLogin = linkCode => {
    return request({
        method: 'POST',
        url: '/admin/showLogin?linkCode=' + linkCode
    })
}

// 注销登录
export const quitLogin = () => {
    return request({
        method: 'GET',
        url: '/admin/logout'
    })
}

//修改密码
export const updatePassword = data => {
    return request({
        method: 'POST',
        url: '/admin/updatePassword',
        data
    })
}